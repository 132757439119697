import PropTypes from 'prop-types'
import { Box } from '@resident-advisor/design-system'
import useDimensions from '@/hooks/useDimensions'
import FullWidthStyle from './FullWidthStyle'

const FullWidth = ({ children, sizes, ...props }) => (
  <Box {...props}>
    <FullWidthStyle
      sizes={sizes}
      useAbsolutePosition={false}
      data-testid="FullWidthStyleRelative"
    >
      {children}
    </FullWidthStyle>
  </Box>
)

const FullWidthAbsolutePosition = ({ children, sizes, ...props }) => {
  const [ref, { height }] = useDimensions()

  return (
    <Box {...props} height={height}>
      <FullWidthStyle
        ref={ref}
        sizes={sizes}
        useAbsolutePosition
        data-testid="FullWidthStyleAbsolute"
      >
        {children}
      </FullWidthStyle>
    </Box>
  )
}

const sharedPropTypes = {
  sizes: PropTypes.arrayOf(PropTypes.oneOf(['s', 'm', 'l'])).isRequired,
  children: PropTypes.node.isRequired,
}

FullWidthAbsolutePosition.propTypes = sharedPropTypes
FullWidth.propTypes = sharedPropTypes

export { FullWidthAbsolutePosition }
export default FullWidth
